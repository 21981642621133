<template>
  <div class="date-picker month-picker">
    <div class="date-picker__title">
      {{ title }}
    </div>
    <div class="date-picker__icon">
      <img src="@/assets/icons/icon-calendar.svg" alt="date picker">
    </div>
    <el-date-picker
      v-model="date"
      type="month"
      :clearable="false"
      :default-value="new Date()"
      :picker-options="pickerOptions"
      :format="'MMMM yyyy'"
      :editable="false"
      :placeholder="$t('monthPicker.choose_month')"
      @change="handleUpdate"
      :popper-class="locale"
    >
    </el-date-picker>
  </div>
</template>

<script>
export default {
  name: "MonthPicker",
  props: {
    title: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      locale: process.env.VUE_APP_LOCALE,
      date: '',
      pickerOptions: {
        disabledDate: this.disabledDate,
      },
    }
  },
  methods: {
    handleUpdate(val) {
      this.$emit('changeDates', val)
    },
    disabledDate(date) {
      const today = new Date()
      return today < date
     }
  },

}
</script>

<style lang="scss">
  @import "../../assets/styles/helpers/variables";
  @import "../../assets/styles/helpers/mixin";

  .date-picker {
    .el-date-editor {
      .el-input__inner {
        background: none;
        border: none;
        height: auto;
        line-height: inherit;
        padding: 0;
        font-size: 1.6rem;
        text-transform: lowercase;
        color: #333333;
        font-weight: 400;
        line-height: 19px;
      }

      .el-input__prefix {
        display: none;
      }
    }
  }

  .month-picker {
    .el-date-editor.el-input {
      width: 100%;
      height: 50px;
      transform: translateY(-20px);
      cursor: pointer;

      .el-input__inner {
        padding-top: 20px;
        cursor: pointer;
      } 
    }
  }
</style>
